/********** Template CSS **********/

.ime-firme {
  color: rgb(228, 193, 74) !important;
}
:root {
  --primary: #AB7442;
  --light: #F5F5F5;
  --dark: #353535;
}

.broj-uspjesnih-projekata {
  font-weight: 600;
}

.btn {
  font-weight: 500;
  transition: .5s;
}

.btn-sm-square {
  width: 34px;
  height: 34px;
  &>svg {
    fill: black;
  }
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}

/*** Navbar ***/
.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

.navbar-light .navbar-nav .nav-link {
  margin-right: 30px;
  padding: 25px 0;
  color: #FFFFFF;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  outline: none;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--primary);
}

@media (max-width: 991.98px) {
  .navbar-light .navbar-nav .nav-link  {
      margin-right: 0;
      padding: 10px 0;
  }

  .navbar-light .navbar-nav {
      border-top: 1px solid #EEEEEE;
  }
}

.navbar-light .navbar-brand,
.navbar-light a.btn {
  height: 75px;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--dark);
  font-weight: 500;
}

.navbar-light.sticky-top {
  top: -100px;
  transition: .5s;
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
      display: block;
      border: none;
      margin-top: 0;
      top: 150%;
      opacity: 0;
      visibility: hidden;
      transition: .5s;
  }

  .navbar .nav-item:hover .dropdown-menu {
      top: 100%;
      visibility: visible;
      transition: .5s;
      opacity: 1;
  }
}


/*** Header ***/
@media (max-width: 768px) {
  .header-carousel .owl-carousel-item {
      position: relative;
      min-height: 500px;
  }
  
  .header-carousel .owl-carousel-item img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
  }

  .header-carousel .owl-carousel-item h5,
  .header-carousel .owl-carousel-item p {
      font-size: 14px !important;
      font-weight: 400 !important;
  }

  .header-carousel .owl-carousel-item h1 {
      font-size: 30px;
      font-weight: 600;
  }
}

.header-carousel .owl-nav {
  position: absolute;
  width: 200px;
  height: 45px;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
}

.header-carousel .owl-nav .owl-prev,
.header-carousel .owl-nav .owl-next {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background: transparent;
  border: 1px solid #FFFFFF;
  font-size: 22px;
  transition: .5s;
}

.header-carousel .owl-nav .owl-prev:hover,
.header-carousel .owl-nav .owl-next:hover {
  background: var(--primary);
  border-color: var(--primary);
}

.header-carousel .owl-dots {
  position: absolute;
  height: 45px;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 15px;
  height: 15px;
  background: transparent;
  border: 1px solid #FFFFFF;
  transition: .5s;
}

.header-carousel .owl-dot::after {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  top: 4px;
  left: 4px;
  background: transparent;
  border: 1px solid #FFFFFF;
}

.header-carousel .owl-dot.active {
  background: var(--primary);
  border-color: var(--primary);
}

.screen-head {
  background: linear-gradient(rgba(53, 53, 53, .7), rgba(53, 53, 53, .7)), url(./img/carousel-1.jpg) center center no-repeat;
  background-size: cover;
}

/*** Section Title ***/
.screen-top-title h1 {
  position: relative;
  display: inline-block;
  padding: 0 60px;
}

.screen-top-title.text-start h1 {
  padding-left: 0;
}

.screen-top-title h1::before,
.screen-top-title h1::after {
  position: absolute;
  content: "";
  width: 45px;
  height: 5px;
  bottom: 0;
  background: var(--dark);
}

.screen-top-title h1::before {
  left: 0;
}

.screen-top-title h1::after {
  right: 0;
}

.screen-top-title.text-start h1::before {
  display: none;
}


/*** About ***/
@media (min-width: 992px) {
  .container.about {
      max-width: 100% !important;
  }

  .about-text  {
      padding-right: calc(((100% - 960px) / 2) + .75rem);
  }
}

@media (min-width: 1200px) {
  .about-text  {
      padding-right: calc(((100% - 1140px) / 2) + .75rem);
  }
}

@media (min-width: 1400px) {
  .about-text  {
      padding-right: calc(((100% - 1320px) / 2) + .75rem);
  }
}


/*** Service ***/
.service-item img {
  transition: .5s;
}

.service-item:hover img {
  transform: scale(1.1);
}


/*** Feature ***/
@media (min-width: 992px) {
  .container.feature {
      max-width: 100% !important;
  }

  .feature-text  {
      padding-left: calc(((100% - 960px) / 2) + .75rem);
  }
}

@media (min-width: 1200px) {
  .feature-text  {
      padding-left: calc(((100% - 1140px) / 2) + .75rem);
  }
}

@media (min-width: 1400px) {
  .feature-text  {
      padding-left: calc(((100% - 1320px) / 2) + .75rem);
  }
}


/*** Quote ***/
@media (min-width: 992px) {
  .container.quote {
      max-width: 100% !important;
  }

  .quote-text  {
      padding-right: calc(((100% - 960px) / 2) + .75rem);
  }
}

@media (min-width: 1200px) {
  .quote-text  {
      padding-right: calc(((100% - 1140px) / 2) + .75rem);
  }
}

@media (min-width: 1400px) {
  .quote-text  {
      padding-right: calc(((100% - 1320px) / 2) + .75rem);
  }
}

/*** Contact ***/
@media (min-width: 992px) {
  .container.contact {
      max-width: 100% !important;
  }
}

@media (min-width: 1200px) {
  .contact-text  {
      padding-left: calc(((100% - 1140px) / 2) + .75rem);
  }
}

@media (min-width: 1400px) {
  .contact-text  {
      padding-left: calc(((100% - 1320px) / 2) + .75rem);
  }
}


.footer {
  background: linear-gradient(rgba(53, 53, 53, .7), rgba(53, 53, 53, .7)), url(./img/footer.jpg) center center no-repeat;
  background-size: cover;

  .telefon {
    margin-left: 1.5em;
  }
  .contact-info {
    display: flex;
    &.phone {
      flex-direction: column;
    }
  }
}

.footer .btn.btn-social {
  margin-right: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  border: 1px solid #FFFFFF;
  transition: .3s;
}

.footer .btn.btn-social:hover {
  color: var(--primary);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: #FFFFFF;
  font-size: 15px;
  font-weight: normal;
  text-transform: capitalize;
  transition: .3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  color: var(--primary);
  letter-spacing: 1px;
  box-shadow: none;
}


.header {
  .phone-divider {
    margin: 0 10px;
  }
  .phone-icon-wrapper {
    margin-right: 3px;
  }
  .logo-wrapper {
    &>img {
      width: 90px;
      height: 70px;
    }
  }
}

.footer {
  .logo-wrapper {
    &>img {
      width: 130px;
      height: 100px;
      border-radius: 20px;
      background-color: #fff;
    }
  }
}

.logo-wrapper {
  &>img {
    width: 130px;
    height: 100px;
    border-radius: 20px;
  }
}

.header{
  .phone-numbers{
    font-weight: 600;
  }
}