.services {
  .image-wrapper {
    .image-legend {
      .naslov {
        color: rgb(228, 193, 74) !important;
        font-size: 22px;
      }

      .opis-slike {
        font-family: 'Lora', serif;
      }
    }
  }
}