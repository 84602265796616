.custom-carousel {
  margin: 20px;
  .my-image {
    // width: 320px !important;
  }
}

.carousel-image {
  position: relative;
  overflow: hidden;
}

.carousel-image img {
  width: 100%;
  height: auto;
  max-height: 470px;
  border-radius: 8px; /* Add rounded corners for a nicer look */
}
